<template>
  <div
    :class="[
      'scroller',
      isScrollable && 'scroller--scrollable',
      isDragging && 'scroller--dragging',
      hasScrolled && 'scroller--scrolled',
      hasScrolledToEnd && 'scroller--scrolledToEnd'
    ]"
    :style="`--pageMargin:${pageMargin}px`"
  >
    <div class="scroller__nav row no-gutters">
      <div class="col-auto">
        <button
          class="scroller__nav-arrow--left"
          @click="scroll(-scrollAmount)"
        >
          <OsIcon name="ArrowUp" class="text-white" size="0.825rem" />
        </button>
      </div>
      <div class="spacer"></div>
      <div class="col-auto">
        <button
          class="scroller__nav-arrow--right"
          @click="scroll(scrollAmount)"
        >
          <OsIcon name="ArrowUp" class="text-white" size="0.825rem" />
        </button>
      </div>
    </div>
    <div
      ref="scroller"
      class="scroller__content"
      @scroll.passive="onScroll"
      @mousedown="onMouseDown"
    >
      <slot :is-dragging="isDragging" />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Scroller',

  props: {
    scrollAmount: {
      type: Number,
      default: 100
    },
    snap: {
      type: Boolean,
      default: false
    },
    trackingOptions: {
      type: Object,
      default: () => {}
    },
    pageMargin: {
      type: Number,
      default: 32
    }
  },

  setup(props) {
    const { $dataLayer } = useNuxtApp()
    const scroller = ref<HTMLElement>()

    const animationLoop = ref(0)
    const isScrollable = ref(false)
    const scrollPosition = ref(0)
    const dragSpeed = ref(0.91)
    const oldX = ref(0)
    const speed = ref(0)
    const scrolledToEnd = ref(false)
    const isDragging = ref(false)
    const timeout = ref()

    const hasScrolled = computed(() => scrollPosition.value > 0)
    const hasScrolledToEnd = computed(() => scrolledToEnd.value)
    const margin = computed(
      () => (window.innerWidth - Math.min(window.innerWidth, 1232)) / 2 + 32
    )

    const updatePosition = (el: HTMLElement) => {
      scrollPosition.value = el.scrollLeft
      scrolledToEnd.value =
        el.scrollLeft + 30 >= el.scrollWidth - el.clientWidth
    }

    const updateState = () => {
      const el = scroller.value as HTMLElement
      if (el) {
        isScrollable.value = el.scrollWidth > el.clientWidth
        updatePosition(el)
      }
    }

    const onScroll = () => updateState()

    onMounted(() => nextTick(() => updateState()))

    onBeforeUnmount(() => {
      clearTimeout(timeout.value)
      if (animationLoop.value) window.cancelAnimationFrame(animationLoop.value)
    })

    const update = () => {
      const el = scroller.value as HTMLElement
      el.scrollLeft -= speed.value
      updateState()
    }

    const animate = () => {
      speed.value *= dragSpeed.value
      update()
      if (Math.abs(speed.value) <= 3) {
        window.cancelAnimationFrame(animationLoop.value)
      } else {
        animationLoop.value = window.requestAnimationFrame(animate)
      }
    }

    const onMouseMove = (e: MouseEvent) => {
      const el = scroller.value as HTMLElement
      speed.value = e.clientX - oldX.value
      el.scrollLeft -= speed.value
      oldX.value = e.clientX
      isDragging.value = true
    }
    const onMouseOut = () => {
      const el = scroller.value as HTMLElement
      el?.removeEventListener('mousemove', onMouseMove)

      timeout.value = setTimeout(() => {
        isDragging.value = false
      }, 100)
    }

    const onMouseUp = (e: MouseEvent) => {
      e.preventDefault()

      onMouseOut()
      if (Math.abs(speed.value) > 3) {
        animationLoop.value = window.requestAnimationFrame(animate)
      }
    }

    const onMouseDown = (e: MouseEvent) => {
      e.preventDefault()

      const el = scroller.value as HTMLElement

      if (el) {
        oldX.value = e.clientX
        el.addEventListener('mousemove', onMouseMove)
        window.addEventListener('mouseup', onMouseUp)
        el.addEventListener('mouseout', onMouseOut)
      }
    }

    const getCurrentIndex = () => {
      const scrollerEl = scroller.value as HTMLElement

      if (scrollerEl.childNodes.length > 1) {
        for (let i = 0; i < scrollerEl.childNodes.length; i++) {
          const el = scrollerEl.childNodes[i] as HTMLElement
          const scrollLeft = scrollerEl.scrollLeft + margin.value
          if (
            scrollLeft >= el.offsetLeft &&
            scrollLeft <= el.offsetLeft + el.offsetWidth
          ) {
            return i
          }
        }
      }
      return 0
    }

    const scroll = (offset: number) => {
      const el = scroller.value as HTMLElement
      if (el) {
        if (props.snap && el.childNodes.length > 1) {
          const currentIndex = getCurrentIndex()
          const length = el.childNodes.length
          const index = Number(
            currentIndex +
              (offset > 0
                ? currentIndex >= length
                  ? currentIndex
                  : 1
                : currentIndex <= 0
                  ? 0
                  : -1)
          )

          const slide = el.childNodes[index] as HTMLElement
          const diff =
            (window.innerWidth - Math.min(window.innerWidth, 1232)) / 2
          const left = slide?.offsetLeft - diff - 8

          el.scroll({
            left,
            behavior: 'smooth'
          })
        } else {
          const left = el.scrollLeft
          el.scroll({
            left: left + offset,
            behavior: 'smooth'
          })
        }
      }

      $dataLayer.linkClick({
        action: offset > 0 ? 'RIGHT' : 'LEFT',
        ...props.trackingOptions
      })
    }

    return {
      isScrollable,
      isDragging,
      hasScrolled,
      hasScrolledToEnd,
      onScroll,
      onMouseDown,
      scroll,
      updateState,
      scroller
    }
  }
}
</script>

<style lang="scss" scoped>
.scroller {
  position: relative;
  display: inline-block;
  width: fit-content;
  max-width: 100%;

  .scroller__nav {
    visibility: hidden;
    opacity: 0;
    display: none;
    max-width: 100vw;

    .scroller__nav-arrow--left {
      opacity: 0;
      pointer-events: none;
    }

    @media (hover: hover) {
      display: flex;
    }
  }

  &--dragging {
    cursor: grabbing;
  }

  &--scrollable:hover {
    .scroller__nav {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s;
      z-index: 3;
    }
  }

  &--scrolled {
    .scroller__nav {
      .scroller__nav-arrow--left {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &--scrolledToEnd {
    .scroller__nav {
      .scroller__nav-arrow--right {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

.scroller__content {
  width: fit-content;
  max-width: calc(100% + var(--pageMargin));
  margin: 0 calc(-1 * var(--pageMargin) / 2);
  padding: 0 calc(var(--pageMargin) / 2);
  overflow-y: visible;
  overflow-x: auto;
  scrollbar-width: none;

  @media (hover: none) and (pointer: coarse) {
    scroll-snap-type: x mandatory;

    :deep(.galleryImage) {
      scroll-snap-align: center;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.scroller__nav {
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0 rem(8);
  pointer-events: none;
  z-index: 4;

  button {
    border: none;
    width: 34px;
    height: 34px;
    display: flex;
    border-radius: rem(4);
    background-color: #15151566;
    pointer-events: all;
    cursor: pointer;
    @include transitions();

    svg {
      margin: auto;
    }
  }
  .scroller__nav-arrow--left {
    svg {
      transform: rotate(-90deg);
    }
  }
  .scroller__nav-arrow--right {
    svg {
      transform: rotate(90deg);
    }
  }
}
</style>
